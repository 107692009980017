<style scoped>
/* 62E3C6 98, 227, 198*/
.template_box {
	height: 100%;
	width: 100%;
	background-image: url(../static/img/login.png);
	background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
	position: relative;
	display: flex;
	align-items: center;
}
.logn_box {
	position: absolute;
	padding: 1rem 4rem;
	box-shadow: 0 0 10px #ccc;
	background-color: rgba(0, 0, 0, 0.0);
	right: 5%;
}
.title {
	font-size: 1rem;
	color: #333;
}
.title img{
	width: 5rem;
	vertical-align: middle;
}
.logn_box_item {
	margin-bottom: 1rem;
}
.login_btn {
	width: 100%;
	font-size: 1.3rem;
	background-color: rgb(245,195,79);
	border: none;
}
.logo-box{
	background-color: #FFF;
	position: absolute;
	right: 3%;
	top: 0;
}
.logo-box img{
	width: 8rem;display: block;
}
.footer--box{
	position: absolute;
	bottom: 0;left: 0;background-color: #FFF;
	width: 100%;
	text-align: center;
	font-size: 12px;
	padding: 3px 0;
	color: #888888;
}
</style>

<template>
	<div class="template_box">
		<div class="logo-box">
			<img src="../static/img/logo.png" alt="">
		</div>
		<div class="logn_box">
			<div class="logn_box_item title">
				<img src="../static/img/logo-comp.png" alt="">
				晴朗社区+</div>
			<div class="logn_box_item"><el-input placeholder="请输入账号" v-model="account" clearable></el-input></div>
			<div class="logn_box_item"><el-input placeholder="请输入密码" v-model="password" clearable show-password></el-input></div>
			<div class="logn_box_item">
				<el-button class="login_btn" type="primary" :loading="btnLoading" @click="goLogin">{{ btnLoginText }}</el-button>
			</div>
		</div>
		<div class="footer--box">
			版权所有 &copy copyright 四川纷知软件开发有限公司 丨 蜀ICP备18026354号-6
		</div>
	</div>
</template>

<script>
var _this;
export default {
	name: 'loginPage',
	data() {
		return {
			account: '',
			password: '',
			btnLoading: false,
			btnLoginText: '登录'
		};
	},
	created: function() {
		_this = this;
	},
	mounted: function() {},
	methods: {
		//——** 登录
		goLogin() {
			var params = {
				account: _this.account,
				password: _this.password
			};
			_this.btnLoading = true;
			_this.btnLoginText = '登录中';
			_this
				._postApi('/wy/login', params)
				.then(res => {
					if (res.code != 1) {
						_this.btnLoading = false;
						_this.btnLoginText = '登录';
						_this
							.$confirm(res.msg, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							})
							.then(() => {})
							.catch(() => {});
					} else {
						localStorage.setItem('token', res.data.token);
						var userInfoJsonStr = JSON.stringify({
							name: res.data.name,
							account: res.data.account
						});
						localStorage.setItem('userInfo', userInfoJsonStr);
						localStorage.setItem('companyName', res.data.company_name);
						_this.btnLoading = false;
						_this.btnLoginText = '登录';
						_this.$router.replace({
							name: 'homePage',
							query: { i: 0 }
						});
					}
				})
				.catch(err => {});
		}
	}
};
</script>
